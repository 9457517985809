n
<template>
  <v-container>
    <v-tabs v-model="dashboardTab" grow show-arrows>
      <v-tab v-for="(tab, value) in tabs" :value="value" :disabled="tab.disabled" nuxt="true"
        :to="`/dashboard/${value}`">
        {{ tab.title }}
      </v-tab>
    </v-tabs>

    <slot />
  </v-container>
</template>

<script setup lang="ts">
const tabs: { [key: string]: { title: string; disabled: boolean } } = {
  overall: { title: "Overall", disabled: false },
  eia: { title: "Environmental", disabled: false },
  ems: { title: "EMS", disabled: false },
  assets: { title: "Assets", disabled: false },
  hcm: { title: "HCM", disabled: true },
  mfg: { title: "MFG", disabled: true },
};

const route = useRoute();
const dashboardTab = ref(route.path);
</script>
<style scoped>
.v-tabs,
.v-tab {
  font-family: "Noto Sans", sans-serif;
}

.selected-tab {
  color: #001a21;
  font-weight: 700;
}

.unselected-tab {
  color: #808b95;
}
</style>
